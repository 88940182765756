import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Blog from "../components-tr/blog.tr"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components-tr/seo"
import EBookBanner from "../components/eBookBanner"

const BlogPostTemplate = ({ data: { previous, next, post, allPost } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  const avatarUrl = post.author.node.avatar.url

  const randomPost =
    allPost.nodes[Math.floor(Math.random() * allPost.nodes.length)]

  const metaImage = 
    post.featuredImage?.node?.localFile?.childImageSharp
      ? post.featuredImage?.node?.localFile?.childImageSharp?.resize
      : null

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo
        title={post.seo.title}
        description={post.seo.metaDesc}
        image={metaImage}
        canonical={`https://tio.ist${post.seo.canonical}`}
        schema={JSON.parse(post.seo.schema.raw)}
      />
      <div className="container pt-10 px-5 max-w-4xl mx-auto">
        <article className="pt-10">
          {post.categories?.nodes &&
            <div className="text-center flex gap-x-4 justify-center">
              {post.categories.nodes.map(category => (
                <Link to={`/tr/blog/category/${category.slug}`} lang="tr" key={category.id} className="font-bold text-blue uppercase text-center">{category.name}</Link> //link to /blog/category/category.slug
              ))}
            </div>}

          <h1
            className="text-4xl text-blue leading-normal font-bold text-center pt-12"
            itemProp="headline"
          >
            {parse(post.title)}
          </h1>
          <div className="pb-16">
            <p className="italic font-light py-5 text-center text-blue">{post.date}</p>
          </div>

          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.data && (
            <GatsbyImage
              className="mx-auto mb-12"
              image={featuredImage.data}
              alt={featuredImage.alt}
            />
          )}

          {!!post.content && (
            <section
              className="blog-post py-5 leading-8 text-black text-xl"
              itemProp="articleBody"
            >
              {parse(post.content)}
            </section>
          )}
          <Link to={`/tr/blog/author/${post.author.node?.slug}`} className="inline-flex">
            <div className="inline-flex">
              {avatarUrl && (
                <img
                  alt={post.author.node?.name || ``}
                  className="mr-4 rounded-full w-8 h-8"
                  src={avatarUrl}
                />
              )}
              {post.author.node?.name && (
                <p className="self-center text-blue">
                  <strong className="block text-2xl font-bold">{post.author.node.name}</strong>
                </p>
              )}
            </div>
          </Link>

          <div className="flex flex-wrap gap-x-6 gap-y-5 mt-10">
            {post.tags.nodes?.map(tag => <Link to={`/tr/blog/tag/${tag.slug}`} key={tag.id} lang="tr" className="text-[#848484] text-sm font-thin uppercase">#{tag.name}</Link>)}
          </div>
        </article>
      </div>
      <div className="mt-20 md:mt-36 mb-12">
        <EBookBanner />
      </div>
      <nav className="mx-auto max-w-4xl container overflow-hidden">
        <div className="mt-8 mb-16 lg:flex pb-10 -mx-5">
          {previous ? (
            <Blog
              key={previous.id}
              title={previous.title}
              uri={previous.uri}
              featuredImage={{
                data: previous.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
                alt: previous.featuredImage?.node?.altText || ``,
              }}
            />
          ) : (
            <Blog
              key={randomPost.id}
              title={randomPost.title}
              uri={randomPost.uri}
              featuredImage={{
                data: randomPost.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
                alt: randomPost.featuredImage?.node?.altText || ``,
              }}
            />
          )}

          {next ? (
            <Blog
              key={next.id}
              title={next.title}
              uri={next.uri}
              featuredImage={{
                data: next.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
                alt: next.featuredImage?.node?.altText || ``,
              }}
            />
          ) : (
            <Blog
              key={randomPost.id}
              title={randomPost.title}
              uri={randomPost.uri}
              featuredImage={{
                data: randomPost.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
                alt: randomPost.featuredImage?.node?.altText || ``,
              }}
            />
          )}
        </div>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByIdTr(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        schema {
          articleType
          pageType
          raw
        }
      }
      categories {
        nodes {
          id
          slug
          name
        }
      }
      tags {
        nodes {
          id
          slug
          name
        }
      }
      author {
        node {
          id
          slug
          avatar {
            url
          }
          name
          uri
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            
            childImageSharp {
              resize(width: 1200) {
                src
                height
                width
              }
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    allPost: allWpPost(
      filter: { language: { slug: { eq: "tr" } }, id: { ne: $id } }
    ) {
      nodes {
        id
        uri
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      id
      uri
      title
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    next: wpPost(id: { eq: $nextPostId }) {
      id
      uri
      title
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
